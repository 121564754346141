import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Post from '../templates/Post';
import Meta from '../components/Meta';
import Layout from '../components/Layout';
import Interstitial from '../components/Interstitial';

const WhatsAppoverseas = ({ data, location }) => (
  <Layout location={location}>
    <Meta
      title="I'm voting. Here's how to get it sorted - now."
      description="Make sure your friends and family know how, too. Click here to send them this info"
      shareImage="overseas"
      location={location}
      site={get(data, 'site.meta')}
      whatsAppMessage={`I may not live in the UK, but I know my voice matters. Your voice matters, too!
🗳That’s why I’m voting! 🗳
Are you voting, too?

Brits outside the UK, esp. in the EU, will be affected by the outcome of this election. It's important you get your say!

Registered or not, you have to renew every year. You can also re-register if you're not sure.
You'll probably also want to nominate an in-person 🤝proxy🤝 to vote on your behalf.
⌛You only have until 26 November! ⌛

*FIRST* make sure your friends and family outside the UK don’t lose their chance to be heard:
📲 Click here to send them this message:📲
👉 https://saveourfuture.uk/overseas 👈

*THEN* like me, use this handy tool to break down the process:
👉 https://forwarddemocracy.com/britsabroad 👈

🚨 IMPORTANT🚨
While Brits abroad can vote by post, there have been many problems with receiving postal ballots in the recent past.
Now even Gov UK recommends nominating a proxy to vote on your behalf.
Check the link for advice on how to select and nominate your proxy.

👫 FORWARD THIS MESSAGE 👫
🚨 Make sure your friends and family outside the UK don’t lose their chance to be heard. 🚨

📲 Click here to send them this message via WhatsApp: 📲
👉 https://saveourfuture.uk/overseas 👈

Or send them this link however you prefer:
👉 https://forwarddemocracy.com/britsabroad 👈`}
    />
    <Interstitial />
  </Layout>
);

WhatsAppoverseas.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default WhatsAppoverseas;

export const query = graphql`
  query WhatsAppoverseasQuery {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
        twitter
        adsense
      }
    }
  }
`;
